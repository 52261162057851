
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('i', {
                'className': 'fa fa-search',
                'aria-hidden': 'true'
            }), _createElement('svg', {
                'aria-hidden': 'true',
                'focusable': 'false',
                'role': 'presentation',
                'className': 'icon',
                'viewBox': '0 0 1200 1200'
            }, _createElement('svg', {
                'x': '24%',
                'y': '24%'
            }, _createElement('path', {
                'fillRule': 'evenodd',
                'clipRule': 'evenodd',
                'd': 'M606.209,578.714L448.198,423.228C489.576,378.272,515,318.817,515,253.393C514.98,113.439,399.704,0,257.493,0\n    C115.282,0,0.006,113.439,0.006,253.393s115.276,253.393,257.487,253.393c61.445,0,117.801-21.253,162.068-56.586\n    l158.624,156.099c7.729,7.614,20.277,7.614,28.006,0C613.938,598.686,613.938,586.328,606.209,578.714z M257.493,467.8\n    c-120.326,0-217.869-95.993-217.869-214.407S137.167,38.986,257.493,38.986c120.327,0,217.869,95.993,217.869,214.407\n    S377.82,467.8,257.493,467.8z'
            }))));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]