
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableTabDesktopRT () {
    return this.vehicleSpecific || this.manyFitments ? _createElement('a', {
        'className': 'ishi-nav-link',
        'data-href': '#tab-fitment',
        'key': '0'
    }, '\n  Fitment\n') : null;
}
        export const componentNames = []