//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-608:_7688,_3736,_7656,_8856,_4896,_5050,_2634,_9240;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-608')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-608', "_7688,_3736,_7656,_8856,_4896,_5050,_2634,_9240");
        }
      }catch (ex) {
        console.error(ex);
      }